import React, { useEffect, useState } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { isNil } from "ramda";

import { getFromCookie, isAuthenticated } from "../code/api/auth";
import { setAuthAction } from "../code/redux/authSlice";
import { DownloadProvider } from "./DownloadContext";
import FloatingDownloadIndicator from "./FloatingDownloadIndicator";
import Login from "../code/pages/Login";
import NotFound from "../code/pages/NotFound";
import Layout from "../code/components/Layout";
import Home from "../code/pages/Home";
import Roles from "../code/pages/Roles";
import Users from "../code/pages/Users";
import Alarms from "../code/pages/Alarms";
import Analysis from "../code/pages/Analysis";
import Structures from "../code/pages/Structures";
import AddStructure from "../code/pages/Structures/components/AddStructure";
import WorkingPage from "../code/pages/WorkingPage";
import { toast } from "react-toastify";
import Configuration from "../code/pages/Config";
import Equipments from "../code/pages/Equipments";
import Documentation from "../code/pages/Documentation";
import Download from "../code/pages/Download";

const App = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authData);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    !isNil(authData) && isNil(authData.isAuthenticated) && checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData?.isAuthenticated]);

  const checkAuth = async () => {
    try {
      const authStatus = await isAuthenticated();
      if (!authStatus) {
        dispatch(setAuthAction(null));
      } else {
        dispatch(setAuthAction(getFromCookie("uptechUID")));
      }
      setAuthChecked(true);
    } catch (error) {
      toast.error(error, { className: "toast-error" });
    }
  };

  if (!authChecked) {
    return (
      <div
        style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}
      >{`${intl.formatMessage({ id: "loading" })}...`}</div>
    );
  }

  return (
    <DownloadProvider>
      <Router>
        <Routes>
          <Route path="/login" element={isNil(authData.isAuthenticated) ? <Login /> : <Navigate to="/" replace />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={!isNil(authData.isAuthenticated) ? <Layout /> : <Navigate to="/login" replace />}>
            <Route index element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/users" element={<Users />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/alarms" element={<Alarms />}>
              <Route path=":assetName" />
            </Route>
            <Route path="/analysis" element={<Analysis />}>
              <Route path=":assetName" />
              <Route path=":assetName/:startPointIndex" />
              <Route path=":assetName/:startPointIndex/:endPointIndex" />
              <Route path=":assetName/:dateTime/:startPointIndex/:endPointIndex" />
              <Route path=":assetName/:dateTime/:startPointIndex/:endPointIndex/:startDate" />
              <Route path=":assetName/:dateTime/:startPointIndex/:endPointIndex/:startDate/:alertMeasure" />
            </Route>
            <Route path="/structures" element={<Structures />} />
            <Route path="/add_structure" element={<AddStructure />} />
            <Route path="/config" element={<Configuration />} />
            <Route path="/equipments" element={<Equipments />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/downloads" element={<Download />} />
            <Route path="/monitor" element={<WorkingPage />} />
          </Route>
        </Routes>
        <FloatingDownloadIndicator />
      </Router>
    </DownloadProvider>
  );
};

export default App;
