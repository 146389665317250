import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { isEmpty, isNil } from "ramda";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import AssetInfoForm from "../AssetInfoForm";
import { ThemeProvider } from "styled-components";
import { getMuiTheme } from "../../../../utils/utils";

import PolylineData from "../PolylineData/polylineData";
import EquipmentInfo from "../EquipmentInfo/equipmentInfo";
import palette from "../../../../../config/colorPalette";
import { saveAsset } from "../ThresholdsValues/utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const AddStructure = () => {
  const intl = useIntl();
  const theme = useMemo(() => getMuiTheme(), []);
  const navigate = useNavigate();

  const userData = useSelector((state) => state.userData.data);
  const configData = useSelector((state) => state.configData.data);
  const equipmentTypes = useSelector((state) => state.equipmentTypeData.data);
  const [objectMeasures, setObjectMeasures] = useState(null);
  useEffect(() => {
    if (!isNil(equipmentTypes)) {
      const newData = equipmentTypes.map((item) => {
        const measures = [];
        const computing = [];
        item.pMeasureNames.forEach((measure, index) => {
          if (item.pMeasureComputed[index] === 0) {
            measures.push(measure);
          } else {
            computing.push(measure);
          }
        });

        const newEntry = { name: item.name, measures: measures };
        if (computing.length > 0) {
          newEntry.computing = computing;
        }
        return newEntry;
      });
      setObjectMeasures(newData);
    }
  }, [equipmentTypes]);
  const [value, setValue] = useState(1);
  const [errors, setErrors] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);

  const [assetName, setAssetName] = useState("");
  const [URL, setURL] = useState("");
  const [zoom, setZoom] = useState(12);
  const [demarcation, setDemarcation] = useState(
    !isNil(configData) && !isNil(configData.demarcations) && !isEmpty(configData.demarcations)
      ? configData.demarcations[0]
      : "",
  );
  const [alertTimeRange, setAlertTimeRange] = useState(24);
  const [alertTimeMeasure, setAlertTimeMeasure] = useState(1);
  const [avatar, setAvatar] = useState("");
  const [equipmentCollection, setEquipmentCollection] = useState([""]);
  const [measures, setMeasures] = useState({ equipment1: [""] });
  const [measuresComputed, setMeasuresComputed] = useState({ equipment1: [] });
  const [measuresAlerts, setMeasuresAlerts] = useState({ equipment1: [0] });
  const [thresholdsValues, setThresholdsValues] = useState({ equipment1: [[[[{ type: null, value: 0 }]]]] });
  const [thresholdsRanges, setThresholdsRanges] = useState({ equipment1: [[[[0, 1]]]] });
  const [zones, setZones] = useState([[[1]]]);
  const [polylineDisplay, setPolylineDisplay] = useState([]);
  //polyline
  const [polyline, setPolyline] = useState([]);
  const [centroid, setCentroid] = useState([]);
  const [pIndexes, setPIndexes] = useState(null);
  //equipment
  const [equipment, setEquipment] = useState([]);
  const [infoToggle, setInfoToggle] = useState([]);
  const [channelAsignToggle, setChannelAsignToggle] = useState([]);
  const [channelNameAsignToggle, setChannelNameAsignToggle] = useState([]);
  const [channelDataToggle, setChannelDataToggle] = useState([]);
  const [channelPK, setChannelPK] = useState([]);
  const [equipmentInterMeasurement, setEquipmentInterMeasurement] = useState([]);
  const [measureToggle, setMeasureToggle] = useState([]);
  const [channelToggle, setChannelToggle] = useState([]);
  const [zoneToggle, setZoneToggle] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const disabled = useMemo(
    () => !isEmpty(errors) || isEmpty(polyline) || isEmpty(centroid) || isEmpty(equipment),
    [errors, polyline, centroid, equipment],
  );

  return (
    <ThemeProvider theme={theme}>
      {loadingScreen && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(176 176 176 / 60%)",
            zIndex: 1001,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h1>{`${intl.formatMessage({ id: "loading" })}...`}</h1>
        </div>
      )}
      <div style={{ height: "100%", width: "100%", overflowY: "auto" }}>
        <TabContext value={value}>
          <Box sx={{ width: "100%" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab value={1} label={intl.formatMessage({ id: "asset.data" })} />
              <Tab value={2} label={intl.formatMessage({ id: "polyline.data" })} />
              <Tab value={3} label={intl.formatMessage({ id: "equipment.info" })} />
            </Tabs>
          </Box>
          <TabPanel sx={{ flex: 1, overflow: "auto", width: "100%", height: "100%" }} value={1}>
            <AssetInfoForm
              zoom={zoom}
              setZoom={setZoom}
              demarcation={demarcation}
              setDemarcation={setDemarcation}
              URL={URL}
              setURL={setURL}
              assetName={assetName}
              setAssetName={setAssetName}
              avatar={avatar}
              setAvatar={setAvatar}
              equipmentCollection={equipmentCollection}
              setEquipmentCollection={setEquipmentCollection}
              measures={measures}
              setMeasures={setMeasures}
              measuresComputed={measuresComputed}
              setMeasuresComputed={setMeasuresComputed}
              measuresAlerts={measuresAlerts}
              setMeasuresAlerts={setMeasuresAlerts}
              thresholdsValues={thresholdsValues}
              setThresholdsValues={setThresholdsValues}
              errors={errors}
              setErrors={setErrors}
              alertTimeRange={alertTimeRange}
              alertTimeMeasure={alertTimeMeasure}
              setAlertTimeRange={setAlertTimeRange}
              setAlertTimeMeasure={setAlertTimeMeasure}
              zones={zones}
              setZones={setZones}
              thresholdsRanges={thresholdsRanges}
              setThresholdsRanges={setThresholdsRanges}
              objectMeasures={objectMeasures}
              configData={configData}
            />
          </TabPanel>
          <TabPanel value={2}>
            <PolylineData
              setPolyline={setPolyline}
              polylineDisplay={polylineDisplay}
              setPolylineDisplay={setPolylineDisplay}
              centroid={centroid}
              setCentroid={setCentroid}
              pIndexes={pIndexes}
              setPIndexes={setPIndexes}
              intl={intl}
            ></PolylineData>
          </TabPanel>
          <TabPanel value={3}>
            <EquipmentInfo
              equipment={equipment}
              setEquipment={setEquipment}
              equipmentCollection={equipmentCollection}
              polylineDisplay={polylineDisplay}
              centroid={centroid}
              pIndexes={pIndexes}
              assetName={assetName}
              measuresComputed={measuresComputed}
              measures={measures}
              setMeasures={setMeasures}
              measuresAlerts={measuresAlerts}
              setMeasuresAlerts={setMeasuresAlerts}
              thresholdsValues={thresholdsValues}
              setThresholdsValues={setThresholdsValues}
              thresholdsRanges={thresholdsRanges}
              setThresholdsRanges={setThresholdsRanges}
              zones={zones}
              setZones={setZones}
              infoToggle={infoToggle}
              setInfoToggle={setInfoToggle}
              channelAsignToggle={channelAsignToggle}
              setChannelAsignToggle={setChannelAsignToggle}
              channelNameAsignToggle={channelNameAsignToggle}
              setChannelNameAsignToggle={setChannelNameAsignToggle}
              channelDataToggle={channelDataToggle}
              setChannelDataToggle={setChannelDataToggle}
              channelPK={channelPK}
              setChannelPK={setChannelPK}
              equipmentInterMeasurement={equipmentInterMeasurement}
              setEquipmentInterMeasurement={setEquipmentInterMeasurement}
              measureToggle={measureToggle}
              setMeasureToggle={setMeasureToggle}
              channelToggle={channelToggle}
              setChannelToggle={setChannelToggle}
              zoneToggle={zoneToggle}
              setZoneToggle={setZoneToggle}
              errors={errors}
              setErrors={setErrors}
              objectMeasures={objectMeasures}
              intl={intl}
            ></EquipmentInfo>
          </TabPanel>
        </TabContext>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: palette.primary,
            margin: "8px 0",
          }}
        ></div>
        <div>
          <Button
            variant="contained"
            style={{
              backgroundColor: disabled ? palette.light : palette.primary,
              color: "white",
            }}
            disabled={disabled}
            onClick={() => {
              saveAsset(
                assetName,
                zoom,
                demarcation,
                measures,
                measuresAlerts,
                thresholdsValues,
                polyline,
                centroid,
                equipment,
                avatar,
                alertTimeRange,
                alertTimeMeasure,
                URL,
                userData,
                setLoadingScreen,
                thresholdsRanges,
                navigate,
                intl,
              );
            }}
          >
            {intl.formatMessage({ id: "save" })}
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default AddStructure;
