export const BASE_API_URL = "https://kerkini.uptech-sensing.com/api/v1";
//export const BASE_API_URL = "http://192.168.1.51:8000/api/v1";
//export const BASE_API_URL = "http://10.9.0.34:8000/api/v1";
export const AUTH_API_ENDPOINT = "/auth";
export const IS_ONLINE = true;
export const MAP_TYPES = [
  {
    label: "map.regular",
    value: "regular",
    tileLayer: IS_ONLINE ? `http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}` : `${BASE_API_URL}/images/{z}/{x}/{y}`,
  },
  {
    label: "map.satellite",
    value: "satellite",
    tileLayer: "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
  },
  {
    label: "map.terrain",
    value: "terrain",
    tileLayer: "http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
  },
];
