import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { isNil } from "ramda";

import Button from "@mui/material/Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { GridToolbarContainer, GridToolbarDensitySelector, useGridApiContext } from "@mui/x-data-grid";
import { ThemeProvider } from "@mui/material/styles";

import palette from "../../../config/colorPalette";
import { exportToExcel, getMuiTheme } from "../../utils/utils";

const EditToolbar = ({
  btnText,
  btnIcon,
  handleClick,
  btnEnabled = true,
  secondText,
  secondIcon,
  secondClick,
  secondEnabled,
  archived,
  excelExportOptions = null,
}) => {
  const intl = useIntl();
  const apiRef = useGridApiContext();
  const theme = useMemo(() => getMuiTheme(), []);
  return (
    <ThemeProvider theme={theme}>
      <GridToolbarContainer style={{ justifyContent: "space-between" }} data-testid="grid-toolbar-container">
        <div>
          {!isNil(btnText) && (
            <Button
              data-testid="grid-toolbar-btn"
              variant="text"
              style={{ color: btnEnabled ? palette.primary : palette.light }}
              startIcon={btnIcon}
              onClick={handleClick}
              disabled={!btnEnabled}
            >
              {btnText}
            </Button>
          )}
          {!isNil(secondText) && (
            <Button
              data-testid="grid-toolbar-btn"
              variant="text"
              style={{
                color: secondEnabled ? (archived ? palette.light : palette.primary) : palette.light,
                backgroundColor: archived && palette.primary,
              }}
              startIcon={secondIcon}
              onClick={secondClick}
              disabled={!secondEnabled}
            >
              {secondText}
            </Button>
          )}
        </div>
        <div>
          <GridToolbarDensitySelector data-testid="grid-toolbar-density" />
          {!isNil(excelExportOptions) && (
            <Button
              data-testid="grid-toolbar-excel-export-btn"
              variant="text"
              style={{ color: palette.primary }}
              startIcon={<FileDownloadIcon />}
              onClick={() =>
                exportToExcel({
                  data: excelExportOptions.data,
                  sheetName: "Alarms",
                  filename: excelExportOptions.filename,
                  apiRef,
                  intl,
                })
              }
            >
              {intl.formatMessage({ id: "export to excel" })}
            </Button>
          )}
        </div>
      </GridToolbarContainer>
    </ThemeProvider>
  );
};

export default EditToolbar;
